import { Action, combineReducers, configureStore, createAsyncThunk, createSlice, ThunkAction } from "@reduxjs/toolkit";
import { ThunkActionDispatch } from "redux-thunk";
import { Api } from "./api";
import { ChargeControl, Vehicle, VehicleDetails } from "./api-types";
import { delay } from "./utils";

let api: Api | null = null;

export function hasApi(): boolean {
    return api !== null;
}

export function setApi(newApi: Api) {
    api = newApi;
}

function useApi(): Api {
    if (api === null) {
        throw new Error("Cannot use API before it is set!");
    }
    return api;
}

const fetchVehicles = createAsyncThunk(
    "vehicles/fetch",
    async (requestedVin: string | null, thunkApi) => {
        const vehicles = await useApi().getVehicles();
        const requestedVehicle: Vehicle | undefined = vehicles.filter(v => v.vin === requestedVin)[0];
        const requestedVehicleData = requestedVehicle === undefined ? undefined : {
            details: await useApi().getVehicleInfo(requestedVehicle.vin),
            chargeControl: await useApi().getChargeControl(requestedVehicle.vin),
            vehicle: requestedVehicle
        }
        return {
            vehicles,
            requestedVehicleData
        };
    }
)

const vehiclesSlice = createSlice({
    name: "vehicles",
    initialState: {
        vehicles: null as (Vehicle[] | null)
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchVehicles.fulfilled, (state, action) => {
            state.vehicles = action.payload.vehicles
        })
    }
})

export const vehiclesMeta = {
    actions: {
        fetchVehicles
    },
    selectors: {
        vehiclesSelector: (state: RootState) => state.vehicles.vehicles
    }
}

// ---

const loadVehicle = createAsyncThunk(
    "selectedVehicle/load",
    async (vehicle: Vehicle, thunkApi) => {
        const info = await useApi().getVehicleInfo(vehicle.vin);
        const chargeControl = await useApi().getChargeControl(vehicle.vin);
        return { vehicle, details: info, chargeControl }
    }
)

const reloadVehicle = createAsyncThunk<
    { vehicle: Vehicle, details: VehicleDetails, chargeControl: ChargeControl } | null,
    { forceRefresh: boolean },
    { state: RootState }
>(
    "selectedVehicle/reload",
    async (arg, thunkApi) => {
        const { selectedVehicle } = thunkApi.getState();
        if (selectedVehicle.value === null || selectedVehicle.reloadBlockers !== 0) {
            return null
        }

        console.info("Refreshing...");
        const vehicle = selectedVehicle.value.vehicle;
        if (arg.forceRefresh) {
            await useApi().wakeup(vehicle.vin);
        }
        const info = await useApi().getVehicleInfo(vehicle.vin, !arg.forceRefresh);
        const chargeControl = await useApi().getChargeControl(vehicle.vin);
        return { vehicle, details: info, chargeControl }
    }
)

const setPreconditioning = createAsyncThunk(
    "selectedVehicle/setPreconditioning",
    async (arg: { vin: string, newStatus: boolean }, thunkApi) => {
        if (arg.newStatus) {
            await useApi().startPreconditioning(arg.vin);
        } else {
            await useApi().stopPreconditioning(arg.vin);
        }
        await delay(5000);
        (thunkApi.dispatch as ThunkActionDispatch<any>)(reloadVehicle({ forceRefresh: true }));
        return { expectedStatus: arg.newStatus, timeOfExpectation: new Date().toISOString() }
    }
)

const setIsCharging = createAsyncThunk(
    "selectedVehicle/setIsCharging",
    async (arg: { vin: string, newStatus: boolean }, thunkApi) => {
        if (arg.newStatus) {
            await useApi().startCharging(arg.vin);
        } else {
            await useApi().stopCharging(arg.vin);
        }
        await delay(5000);
        (thunkApi.dispatch as ThunkActionDispatch<any>)(reloadVehicle({ forceRefresh: true }));
    }
) 

const limitCharge = createAsyncThunk(
    "selectedVehicle/limitCharge",
    async (arg: { vin: string, newLimit: number }, thunkApi) => {
        return await useApi().limitCharge(arg.vin, arg.newLimit);
    }
)

interface SelectedVehicleSlice {
    vehicle: Vehicle,
    details: VehicleDetails,
    chargeControl: ChargeControl,
    /** ISO String */
    lastChargeControlUpdate: string,
    isSubmittingChargeControlUpdate: boolean,
    isSubmittingPreconditioningUpdate: boolean,
    isSubmittingChargeStateUpdate: boolean,
    expectation: PreconditioningExpecation | undefined
}

export interface PreconditioningExpecation {
    expectedPreconditioningStatus: boolean;
     timeOfExpectation: string;
}

function initialSelectedVehicleSlice(vehicle: Vehicle, details: VehicleDetails, chargeControl: ChargeControl): SelectedVehicleSlice {
    return {
        vehicle, details, chargeControl,
        lastChargeControlUpdate: new Date().toISOString(),
        isSubmittingChargeControlUpdate: false,
        isSubmittingPreconditioningUpdate: false,
        isSubmittingChargeStateUpdate: false,
        expectation: undefined
    }
}

function fiveMinutesAgo(): Date {
    const d = new Date();
    return new Date(d.valueOf() - 5 * 60_000);
}

const selectedVehicleSlice = createSlice({
    name: "selectedVehicle",
    initialState: {
        value: null as null | SelectedVehicleSlice,
        isLoading: false,
        reloadBlockers: 0
    },
    reducers: {
        deselect: (state) => {
            state.value = null
            state.reloadBlockers = 0
        },
        incrementReloadBlockers: (state) => {
            state.reloadBlockers++;
        },
        decrementReloadBlockers: (state) => {
            state.reloadBlockers--;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchVehicles.fulfilled, (state, action) => {
            const payload = action.payload;
            if (payload.requestedVehicleData === undefined) {
                return;
            }
            const reqData = payload.requestedVehicleData;
            if (state.value === null) {
                state.value = initialSelectedVehicleSlice(reqData.vehicle, reqData.details, reqData.chargeControl);
            } else {
                state.value.vehicle = reqData.vehicle;
                state.value.details = reqData.details;
                state.value.chargeControl = reqData.chargeControl;
                state.value.lastChargeControlUpdate = new Date().toISOString();
            }
            state.isLoading = false;
        });
        builder.addCase(loadVehicle.pending, (state, action) => {
            return {
                value: state.value,
                reloadBlockers: state.reloadBlockers,
                isLoading: true
            }
        });
        builder.addCase(loadVehicle.fulfilled, (state, action) => {
            const payload = action.payload;
            if (state.value === null) {
                state.value = initialSelectedVehicleSlice(payload.vehicle, payload.details, payload.chargeControl);
            } else {
                state.value.vehicle = payload.vehicle;
                state.value.details = payload.details;
                state.value.chargeControl = payload.chargeControl;
                state.value.lastChargeControlUpdate = new Date().toISOString();
                if (state.value.expectation !== undefined && new Date(state.value.expectation.timeOfExpectation) < fiveMinutesAgo()) {
                    state.value.expectation = undefined;
                }
            }
            state.isLoading = false;
        });
        builder.addCase(reloadVehicle.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(reloadVehicle.fulfilled, (state, action) => {
            state.isLoading = false;

            const payload = action.payload
            if (payload === null) {
                return;
            }

            if (state.value === null) {
                state.value = initialSelectedVehicleSlice(payload.vehicle, payload.details, payload.chargeControl);
            } else {
                state.value.vehicle = payload.vehicle;
                state.value.details = payload.details;
                state.value.chargeControl = payload.chargeControl;
                state.value.lastChargeControlUpdate = new Date().toISOString();
                if (state.value.expectation !== undefined && new Date(state.value.expectation.timeOfExpectation) < fiveMinutesAgo()) {
                    state.value.expectation = undefined;
                }
            }
        });
        builder.addCase(setPreconditioning.pending, (state, action) => {
            if (state.value !== null) {
                state.value.isSubmittingPreconditioningUpdate = true;
            }
            state.reloadBlockers++;
        });
        builder.addCase(setPreconditioning.fulfilled, (state, action) => {
            if (state.value !== null) {
                state.value.isSubmittingPreconditioningUpdate = false;
                state.value.expectation = {
                    expectedPreconditioningStatus: action.payload.expectedStatus,
                    timeOfExpectation: action.payload.timeOfExpectation
                }
            }
            state.reloadBlockers--;
        });
        builder.addCase(setIsCharging.pending, (state, action) => {
            if (state.value !== null) {
                state.value.isSubmittingChargeStateUpdate = true;
            }
            state.reloadBlockers++;
        });
        builder.addCase(setIsCharging.fulfilled, (state, action) => {
            if (state.value !== null) {
                state.value.isSubmittingChargeStateUpdate = false;
            }
            state.reloadBlockers--;
        });
        builder.addCase(limitCharge.pending, (state, action) => {
            if (state.value !== null) {
                state.value.isSubmittingChargeControlUpdate = true;
            }
            state.reloadBlockers++;
        });
        builder.addCase(limitCharge.fulfilled, (state, action) => {
            if (state.value !== null) {
                state.value.chargeControl = action.payload;
                state.value.lastChargeControlUpdate = new Date().toISOString();
                state.value.isSubmittingChargeControlUpdate = false;
            }
            state.reloadBlockers--;
        });
    }
})

export const selectedVehicleMeta = {
    actions: {
        loadVehicle,
        reloadVehicle,
        deselect: selectedVehicleSlice.actions.deselect,
        incrementReloadBlockers: selectedVehicleSlice.actions.incrementReloadBlockers,
        decrementReloadBlockers: selectedVehicleSlice.actions.decrementReloadBlockers,
        setPreconditioning,
        setIsCharging,
        limitCharge
    },
    selectors: {
        selectedVehicleSelector: (state: RootState) => state.selectedVehicle.value,
        selectedVehicleIsLoadingSelector: (state: RootState) => state.selectedVehicle.isLoading,
    }
}

// ---

export const store = configureStore({
    reducer: combineReducers({
        vehicles: vehiclesSlice.reducer,
        selectedVehicle: selectedVehicleSlice.reducer
    })
})

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
