import { ChargeControl, Vehicle, VehicleDetails } from "./api-types";
import { config } from "./config";

export class Api {
    private baseUrl: string;
    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    async getVehicles(): Promise<Vehicle[]> {
        return await (await fetch(this.baseUrl + "/get_vehicles")).json()
    }

    async getVehicleInfo(vin: string, fromCache: boolean = true): Promise<VehicleDetails> {
        return await (await fetch(this.baseUrl + `/get_vehicleinfo/${vin}?from_cache=${fromCache ? 1 : 0}`)).json()
    }

    async startCharging(vin: string): Promise<void> {
        return await (await fetch(this.baseUrl + `/charge_now/${vin}/1`)).json()
    }

    async stopCharging(vin: string): Promise<void> {
        return await (await fetch(this.baseUrl + `/charge_now/${vin}/0`)).json()
    }

    async scheduleCharging(vin: string, hour: number, minute: number): Promise<void> {
        return await (await fetch(this.baseUrl + `/charge_hour?vin=${vin}&hour=${hour}&minute=${minute}`)).json()
    }

    async limitCharge(vin: string, percentage: number): Promise<ChargeControl> {
        return await (await fetch(this.baseUrl + `/charge_control?vin=${vin}&percentage=${percentage}`)).json()
    }

    async stopChargingAt(vin: string, hour: number, minute: number): Promise<void> {
        return await (await fetch(this.baseUrl + `/charge_control?vin=${vin}&hour=${hour}&minute=${minute}`)).json()
    }

    async getChargeControl(vin: string): Promise<ChargeControl> {
        return await (await fetch(this.baseUrl + `/charge_control?vin=${vin}`)).json()
    }

    async startPreconditioning(vin: string): Promise<void> {
        return await (await fetch(this.baseUrl + `/preconditioning/${vin}/1`)).json()
    }

    async stopPreconditioning(vin: string): Promise<void> {
        return await (await fetch(this.baseUrl + `/preconditioning/${vin}/0`)).json()
    }

    async wakeup(vin: string): Promise<void> {
        return await (await fetch(this.baseUrl + `/wakeup/${vin}`)).json()
    }
}

export const api = new Api(config.baseUrl);