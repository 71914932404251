import { Check, Close } from '@mui/icons-material';
import { AppBar, Box, Button, Card, CircularProgress, IconButton, InputAdornment, Paper, TextField, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Api } from './api';
import { ChargeControl, ChargingDetails, EnergyEntry, Preconditioning, Vehicle, VehicleDetails } from './api-types';
import './App.css';
import { Refresher } from './refresher';
import { selectedVehicleMeta, store, vehiclesMeta, setApi, PreconditioningExpecation as PreconditioningExpectation } from './store';
import SettingsIcon from '@mui/icons-material/Settings';
import { getApiUrlFromCookie } from './cookies';

function App() {
  setApi(new Api("/api"));

  return <>
    <Provider store={store}>
      <PsaCcAppBar settingsVisible={false} />
      <Box sx={{ margin: 2 }}>
        <Content />
      </Box>
    </Provider>
  </>
}



function ConfigComponent(props: { onSetApiUrl: (apiUrl: string) => void }) {
  const [apiUrl, setApiUrl] = useState(getApiUrlFromCookie() ?? "");
  const isValidUrl = apiUrl.startsWith("http");

  return <div>
    <Typography variant="h3">Configuration</Typography>
    <TextField
      required
      label="API Url"
      error={!isValidUrl}
      defaultValue={apiUrl}
      onChange={(e) => setApiUrl(e.target.value)}
      variant="standard"
    />
    <br />
    <Button sx={{ marginTop: 2 }} variant="contained" disabled={!isValidUrl} onClick={() => props.onSetApiUrl(apiUrl)}>Ok</Button>
  </div>;
}

function Content() {
  const refresherRef = useRef<Refresher>();

  const vehicles = useSelector(vehiclesMeta.selectors.vehiclesSelector);
  const selectedVehicle = useSelector(selectedVehicleMeta.selectors.selectedVehicleSelector);
  const selectedVehicleIsLoading = useSelector(selectedVehicleMeta.selectors.selectedVehicleIsLoadingSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (vehicles == null) {
      const prefix = "#/vin/";
      const { hash } = window.location;
      const requestedVin = hash.startsWith(prefix) ? hash.substring(prefix.length) : null;
      dispatch(vehiclesMeta.actions.fetchVehicles(requestedVin));
    }
  }, [vehicles, dispatch]);

  useEffect(() => {
    const refresher = new Refresher(10000, () => {
      dispatch(selectedVehicleMeta.actions.reloadVehicle({ forceRefresh: false }));
    });
    refresher.start();
    refresherRef.current = refresher;
    return () => {
      refresher.stop();
    }
  }, [selectedVehicle, dispatch]);

  if (selectedVehicle !== null) {
    return <VehicleDetailsComponent expectedPreconditioningStatus={selectedVehicle.expectation} isLoading={selectedVehicleIsLoading} vehicle={selectedVehicle.vehicle} details={selectedVehicle.details} chargeControl={selectedVehicle.chargeControl} lastChargeControlUpdate={new Date(selectedVehicle.lastChargeControlUpdate)} isSubmittingChargeStateUpdate={selectedVehicle.isSubmittingChargeStateUpdate} isSubmittingPreconditioningUpdate={selectedVehicle.isSubmittingPreconditioningUpdate} isSubmittingChargeControlUpdate={selectedVehicle.isSubmittingChargeControlUpdate} onBackClick={() => {
      dispatch(selectedVehicleMeta.actions.deselect());
      window.location.hash = "#/";
    }}></VehicleDetailsComponent>;
  }

  return (
    <div>
      {vehicles == null ?
        <div>Loading...</div> :
        <VehiclesComponent vehicles={vehicles} onClick={vehicle => {
          dispatch(selectedVehicleMeta.actions.loadVehicle(vehicle));
          window.location.hash = `#/vin/${vehicle.vin}`;
        }}></VehiclesComponent>
      }
    </div>
  );
}

function VehiclesComponent(props: { vehicles: Vehicle[], onClick: (vehicle: Vehicle) => void }) {
  return <div>
    <Typography variant="h3">Vehicles</Typography>
    {props.vehicles.map(vehicle => <VehicleComponent key={vehicle.vin} vehicle={vehicle} onClick={() => props.onClick(vehicle)}></VehicleComponent>)}
  </div>;
}

function VehicleComponent(props: { vehicle: Vehicle, onClick: () => void }) {
  return <Card onClick={props.onClick} sx={{
    padding: 2,
    ":hover": {
      cursor: 'pointer'
    }
  }}>
    <Typography variant="h4">{props.vehicle.label}</Typography>
    <Typography variant="caption">VIN: {props.vehicle.vin}</Typography>
  </Card>
}

function VehicleDetailsComponent(props: {
  vehicle: Vehicle,
  details: VehicleDetails,
  chargeControl: ChargeControl | null,
  onBackClick: () => void,
  lastChargeControlUpdate: Date,
  isSubmittingChargeControlUpdate: boolean,
  isSubmittingPreconditioningUpdate: boolean,
  isSubmittingChargeStateUpdate: boolean,
  isLoading: boolean,
  expectedPreconditioningStatus: PreconditioningExpectation | undefined
}) {
  const dispatch = useDispatch();
  return <div>
    <Typography variant="h3">{props.vehicle.label} <CircularProgress sx={{ display: props.isLoading ? "auto" : "none" }} /></Typography>
    <Typography variant="caption">VIN: {props.vehicle.vin}</Typography>
    <br />
    <Button disabled={props.isLoading} variant="contained" onClick={() => dispatch(selectedVehicleMeta.actions.reloadVehicle({ forceRefresh: true }))}>Force Refresh</Button>
    <EnergyComponent isLoading={props.isLoading} data={props.details.energy} vin={props.vehicle.vin} chargeControl={props.chargeControl} lastChargeControlUpdate={props.lastChargeControlUpdate} isSubmittingChargeStateUpdate={props.isSubmittingChargeStateUpdate} isSubmittingChargeControlUpdate={props.isSubmittingChargeControlUpdate}></EnergyComponent>
    <PreconditioningComponent isSubmittingPreconditioningUpdate={props.isSubmittingPreconditioningUpdate} expectedStatus={props.expectedPreconditioningStatus} isLoading={props.isLoading} data={props.details.preconditionning} vin={props.vehicle.vin}></PreconditioningComponent>
    <Button variant="contained" onClick={props.onBackClick}>Go back</Button>
  </div>
}

function EnergyComponent(props: {
  data: EnergyEntry[],
  chargeControl: ChargeControl | null,
  vin: string,
  lastChargeControlUpdate: Date,
  isSubmittingChargeControlUpdate: boolean,
  isLoading: boolean,
  isSubmittingChargeStateUpdate: boolean
}) {
  const dispatch = useDispatch();

  if (new Set(props.data.map(entry => entry.type)).size !== props.data.length) {
    throw new Error("Duplicate energy type in " + props.data);
  }

  return <Paper elevation={1} sx={{
    padding: 2,
    marginBottom: 1,
    marginTop: 1
  }}>
    <Typography variant="h4">Energy</Typography>
    {props.data.map(entry => {
      const isCharging = entry.charging.status === "InProgress";
      return <div key={entry.type}>
        <div>Level: {entry.level} %</div>
        <div>Last Updated: {formatApiDate(entry.updated_at)}</div>
        <ChargingDetailsComponent data={entry.charging} />
        <Button variant="contained" disabled={props.isSubmittingChargeStateUpdate || props.isLoading || isCharging} sx={{ marginRight: 1 }} onClick={() => dispatch(selectedVehicleMeta.actions.setIsCharging({ vin: props.vin, newStatus: true }))}>Start Charging</Button>
        <Button variant="contained" disabled={props.isSubmittingChargeStateUpdate || props.isLoading || !isCharging} onClick={() => dispatch(selectedVehicleMeta.actions.setIsCharging({ vin: props.vin, newStatus: false }))}>Stop Charging</Button>
      </div>
    })}
    <ChargeControlComponent isLoading={props.isLoading} chargeControl={props.chargeControl} lastChargeControlUpdate={props.lastChargeControlUpdate} isSubmitting={props.isSubmittingChargeControlUpdate} vin={props.vin} />
  </Paper>;
}

function ChargingDetailsComponent(props: { data: ChargingDetails }) {
  return <div>
    <Typography variant="h5">Charging Details</Typography>
    <div>Status: {props.data.status}</div>
    <div>Plugged: {props.data.plugged ? "Yes" : "No"}</div>
    <div>Charging Mode: {props.data.charging_mode}</div>
    <div>Charging Rate: {props.data.charging_rate} km/h</div>
    <div>Remaining Time: {formatPeugeotTime(props.data.remaining_time, false)}</div>
    <div>Next Delayed Time: {formatPeugeotTime(props.data.next_delayed_time, true)}</div>
  </div>
}

const peugeotTimeRegex = /PT(?:([0-9][0-9]?)H)?(?:([0-9][0-9]?)M)?(?:([0-9][0-9]?)S)?/;
function formatPeugeotTime(str: string, asDate: boolean): string {
  const result = peugeotTimeRegex.exec(str);
  if (result === null) {
    console.warn(`Could not parse time ${str}`);
    return str;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, hStr, mStr, sStr] = result;
  const [h, m, s] = [hStr === undefined ? 0 : Number.parseInt(hStr), mStr === undefined ? 0 : Number.parseInt(mStr), sStr === undefined ? 0 : Number.parseInt(sStr)];

  if (asDate) {
    const paddedH = h < 10 ? `0${h}` : `${h}`;
    const paddedM = m < 10 ? `0${m}` : `${m}`;
    let resultStr = `${paddedH}:${paddedM}`;
    if (s !== 0) {
      const paddedS = s < 10 ? `0${s}` : `${s}`;
      resultStr += `.${paddedS}`;
    }
    return resultStr;
  } else {
    if (h === 0 && m === 0 && s === 0) {
      return "0s";
    }

    let resultStr = "";
    if (h !== 0) {
      resultStr += `${h}h `;
    }
    if (m !== 0) {
      resultStr += `${m}m `;
    }
    if (s !== 0) {
      resultStr += `${s}s `;
    }
    return resultStr;
  }
}

function ChargeControlComponent(props: { isLoading: boolean, chargeControl: ChargeControl | null, lastChargeControlUpdate: Date, isSubmitting: boolean, vin: string }) {
  const dispatch = useDispatch();
  const [editedChargeLimit, setEditedChargeLimit] = useState<number | undefined>(undefined);
  const [lastEdit, setLastEdit] = useState<Date>(new Date(0));
  const [blockedRefresh, setBlockedRefresh] = useState(false);
  const textRef = useRef<HTMLInputElement>();
  if (props.chargeControl === null) {
    return <div>
      <Typography variant="h5">Charge Control</Typography>
      <div>Loading...</div>
    </div>;
  }

  const chargeLimit = props.chargeControl?.percentage_threshold;

  function setEditedChargeLimitWrapper(newEditedChargeLimit: number | undefined) {
    if (editedChargeLimit === newEditedChargeLimit) return;

    if (!blockedRefresh && chargeLimit !== undefined && newEditedChargeLimit !== chargeLimit) {
      dispatch(selectedVehicleMeta.actions.incrementReloadBlockers());
      setBlockedRefresh(true);
    } else if (blockedRefresh && chargeLimit !== undefined && newEditedChargeLimit === chargeLimit) {
      dispatch(selectedVehicleMeta.actions.decrementReloadBlockers());
      setBlockedRefresh(false);
    }
    setLastEdit(new Date());
    setEditedChargeLimit(newEditedChargeLimit);
  }

  if (lastEdit < props.lastChargeControlUpdate) {
    setEditedChargeLimitWrapper(props.chargeControl?.percentage_threshold);
  }

  const isEditing = editedChargeLimit !== chargeLimit;
  const isEditValid = editedChargeLimit !== undefined && editedChargeLimit >= 0 && editedChargeLimit <= 100;
  const canEditBeSubmitted = !props.isLoading && !props.isSubmitting && isEditValid && editedChargeLimit !== chargeLimit;

  const chargeLimitComp = <div>
    <TextField
      inputRef={textRef}
      defaultValue={chargeLimit}
      label="Charge Limit"
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>
      }}
      error={!isEditValid}
      onFocus={(e) => dispatch(selectedVehicleMeta.actions.incrementReloadBlockers())}
      onBlur={(e) => dispatch(selectedVehicleMeta.actions.decrementReloadBlockers())}
      onChange={(e) => {
        setEditedChargeLimitWrapper(parseInt(e.target.value))
      }}
      variant="standard"
    />
    <IconButton disabled={!canEditBeSubmitted} sx={{ display: isEditing ? "auto" : "none" }} onClick={() => {
      dispatch(selectedVehicleMeta.actions.limitCharge({ vin: props.vin, newLimit: editedChargeLimit! }));
    }}>
      <Check />
    </IconButton>
    <IconButton disabled={props.isSubmitting} sx={{ display: isEditing ? "auto" : "none" }} onClick={() => {
      textRef.current!.value = chargeLimit!.toString();
      setEditedChargeLimitWrapper(chargeLimit);
    }}>
      <Close />
    </IconButton>
    <CircularProgress sx={{ display: props.isSubmitting ? "auto" : "none" }} />
    <br />
    {chargeLimit === 100 ? <></> : <Button sx={{ marginTop: 1 }} disabled={props.isLoading || props.isSubmitting} variant="contained" onClick={() => {
      textRef.current!.value = "100";
      setEditedChargeLimitWrapper(100);
      dispatch(selectedVehicleMeta.actions.limitCharge({ vin: props.vin, newLimit: 100 }));
    }}>Reset to 100 %</Button>}
  </div>;

  return <div>
    <Typography variant="h5">Charge Control</Typography>
    {chargeLimitComp}
  </div>
}

function PreconditioningComponent(props: { isLoading: boolean, data: Preconditioning, vin: string, expectedStatus: PreconditioningExpectation | undefined, isSubmittingPreconditioningUpdate: boolean }) {
  const dispatch = useDispatch();
  const isPreconditioning = props.data.air_conditioning.status !== "Disabled";
  const isUnexpected = !props.isLoading && props.expectedStatus !== undefined && isPreconditioning !== props.expectedStatus.expectedPreconditioningStatus;
  return <Paper elevation={1} sx={{
    padding: 2,
    marginBottom: 1
  }}>
    <Typography variant="h4">Preconditioning</Typography>
    <div>Status: {props.data.air_conditioning.status}</div>
    {isUnexpected ? <div style={{ color: "red" }}>This status is unexpected. The actual status may differ. Try force-refreshing in a bit.</div> : <></>}
    <div>Last Updated: {formatApiDate(props.data.air_conditioning.updated_at)}</div>
    <Button variant="contained" disabled={props.isSubmittingPreconditioningUpdate || props.isLoading || isPreconditioning} sx={{ marginRight: 1 }} onClick={() => dispatch(selectedVehicleMeta.actions.setPreconditioning({ vin: props.vin, newStatus: true }))}>Start</Button>
    <Button variant="contained" disabled={props.isSubmittingPreconditioningUpdate || props.isLoading || !isPreconditioning} onClick={() => dispatch(selectedVehicleMeta.actions.setPreconditioning({ vin: props.vin, newStatus: false }))}>Stop</Button>
  </Paper>;
}

function PsaCcAppBar(props: { onSettingsClicked?: () => void, settingsVisible: boolean }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            PSA Car Controller Client
          </Typography>
          {!props.settingsVisible ? <></> : <IconButton onClick={() => (props.onSettingsClicked ?? (() => {}))()}>
            <SettingsIcon style={{ color: "white" }} />
          </IconButton>}
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* To avoid content to be behind the fixed AppBar */}
    </Box>
  );
}

function formatApiDate(apiDate: string): string {
  // Safari does not support the space in "2021-12-19 17:52:35+00:00"
  // so we replace it with a T.
  return new Date(apiDate.replace(' ', 'T')).toLocaleString();
}

export default App;
