export class Refresher {

    private blockers = 0;
    private periodMs: number;
    private intervalId: number | null = null;
    private onRefreshFunc: () => void;

    private visibilityListener: () => void;

    constructor(periodMs: number, onRefreshFunc: () => void = () => { }) {
        this.periodMs = periodMs;
        this.onRefreshFunc = onRefreshFunc;
        this.visibilityListener = () => {
            if (!document.hidden) {
                console.log("Became visible, refreshing...");
                this.onRefreshFunc();
            }
        };
    }

    blockRefresh(): void {
        this.blockers++;
        console.debug("Increased refresh blockers to " + this.blockers);
    }

    releaseRefresh(): void {
        this.blockers--;
        console.debug("Decreased refresh blockers to " + this.blockers);
    }

    setOnRefreshFunc(onRefreshFunc: () => void) {
        this.onRefreshFunc = onRefreshFunc;
    }

    start(): void {
        document.addEventListener("visibilitychange", this.visibilityListener, false);
        this.intervalId = window.setInterval(() => {
            if (this.blockers === 0 && !document.hidden) {
                this.onRefreshFunc();
            }
        }, this.periodMs);
    }

    stop(): void {
        document.removeEventListener("visibilitychange", this.visibilityListener);
        if (this.intervalId !== null) {
            clearInterval(this.intervalId);
        }
    }
}